import { SingleEventTarget } from "@app/single-event-target";
import Deque from "double-ended-queue";

import { SingleTimeout } from "./functions";

export function createWebsocketBaseUrl(): string {
	return `${window.location.protocol.replace("http", "ws")}//${window.location.host}/api/`;
}

/**
 * Checks if the given websocket close event can be safely ignored
 * @param e the event
 * @returns {boolean}
 */
export function wsShouldReconnect(e: CloseEvent): boolean {
	// Server sends away only when we send it first
	return !(e.wasClean && e.code === 1001);
}

const RECONNECT_DELAYS_MS = [0, 500, 1000, 2000, 5000];

export class ReconnectDelay {
	private currentDelay = 0;

	constructor() {}

	next(): number {
		this.currentDelay = Math.min(this.currentDelay + 1, RECONNECT_DELAYS_MS.length);
		return RECONNECT_DELAYS_MS[this.currentDelay - 1];
	}

	reset() {
		this.currentDelay = 0;
	}
}

export class Updater {
	private updaterWebSocket?: WebSocket;
	private readonly reconnectDelay: ReconnectDelay;
	private readonly failedToDisconnectTimeout: SingleTimeout;
	public readonly failedToConnect: SingleEventTarget<Record<string, never>>;
	private target?: (message: any) => void;
	private readonly queue: Deque<any>;
	private readonly handlers: {
		open: (this: WebSocket, event: Event) => void;
		error: (this: WebSocket, event: Event) => void;
		close: (this: WebSocket, event: CloseEvent) => void;
		message: (this: WebSocket, event: MessageEvent) => void;
	};

	constructor() {
		this.failedToConnect = new SingleEventTarget();
		this.reconnectDelay = new ReconnectDelay();
		this.failedToDisconnectTimeout = new SingleTimeout();
		this.queue = new Deque();

		const updater = this;
		this.handlers = {
			open: function (this: WebSocket, event: Event) {
				updater.reconnectDelay.reset();
				console.log("Updates socket opened", {
					event,
					socket: this,
				});
			},
			message: function (this: WebSocket, event: MessageEvent) {
				updater.failedToDisconnectTimeout.clear();
				updater.onSocketMessage(event);
			},
			error: function (this: WebSocket, event: Event) {
				console.log("Updates socket error", {
					event,
					socket: this,
				});
				// No need to do much else, if this error kills the connection we get a closed event anyway.
				// The documentation does not contain any errors that are not fatal
				// and this event has no information about what actually happened (it's pretty useless imo)
			},
			close: function (this: WebSocket, event: CloseEvent) {
				console.log("Updates socket opened", {
					event,
					socket: this,
				});
				updater.stop();

				if (wsShouldReconnect(event)) {
					console.warn("Updates socket closed", event);
					updater.reconnect();
				}
			},
		};

		this.startUpdater();
	}

	setTarget(target: (message: any) => void): void {
		this.target = target;
		while (!this.queue.isEmpty()) {
			this.target(this.queue.pop());
		}
	}

	private onSocketMessage(message: MessageEvent) {
		if (typeof message.data !== "string") {
			console.warn("Non-string message received over updater websocket.");
			return;
		}

		let json = null;
		try {
			json = JSON.parse(message.data);
		} catch (e) {
			console.warn("Invalid JSON received: " + message.data);
			return;
		}

		if (this.target !== undefined) {
			this.target(json);
		} else {
			this.queue.push(json);
		}
	}

	private reconnect() {
		console.assert(this.updaterWebSocket === undefined);
		const delay = this.reconnectDelay.next();
		if (delay === RECONNECT_DELAYS_MS[0]) {
			this.failedToDisconnectTimeout.timeout(() => {
				this.failedToConnect.dispatchEvent({});
			}, 1000);
		}

		console.log(`Updates socket reconnecting in ${delay}ms`);
		const call = () => {
			if (this.updaterWebSocket === undefined) {
				this.startUpdater();
			}
		};
		if (delay === 0) {
			call();
		} else {
			setTimeout(call, delay);
		}
	}

	private startUpdater() {
		console.log("Updates socket connecting");
		console.assert(this.updaterWebSocket === undefined);

		let url = createWebsocketBaseUrl() + "updates";
		if (typeof crypto === "object" && typeof crypto["randomUUID"] === "function") {
			url += "/" + crypto.randomUUID();
		}

		const socket = new WebSocket(url);
		socket.addEventListener("open", this.handlers.open);
		socket.addEventListener("message", this.handlers.message);
		socket.addEventListener("error", this.handlers.error);
		socket.addEventListener("close", this.handlers.close);

		this.updaterWebSocket = socket;
	}

	stop() {
		if (this.updaterWebSocket === undefined) {
			return;
		}

		this.updaterWebSocket.removeEventListener("open", this.handlers.open);
		this.updaterWebSocket.removeEventListener("message", this.handlers.message);
		this.updaterWebSocket.removeEventListener("error", this.handlers.error);
		this.updaterWebSocket.removeEventListener("close", this.handlers.close);
		this.updaterWebSocket.close();
		this.updaterWebSocket = undefined;
	}
}
