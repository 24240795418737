import { emptyElement, getElementById } from "@app/dom";
import { Updater } from "@app/main/updater";
import { requests } from "@app/requests";
import { printWelcomeMessage, setUpFavicons } from "@app/utility";

import "bootswatch/dist/darkly/bootstrap.css";
import "@styles/main/style.scss";

const updater = new Updater();
const meRequest = requests.me.get();

export const LOGIN_PAGE = "login.html";

const Main = import("@app/main/main");

// Called when the DOM is ready.
addEventListener("DOMContentLoaded", () => {
	printWelcomeMessage();
	setUpFavicons();

	// Check if the user is authenticated. Else, redirect to login.
	meRequest.then(
		me => {
			Main.then(({ onDOMContentLoaded }) => onDOMContentLoaded(me, updater));
		},
		jqXHR => {
			if (jqXHR.status === 401) {
				window.location.assign(LOGIN_PAGE);
			} else {
				updater.stop();
				const error = jqXHR.status;
				const h2 = document.createElement("h2");
				h2.innerText = "Could not connect to Leierkasten";
				const p = document.createElement("p");
				p.innerText = "Please try again later";
				const small = document.createElement("small");
				small.innerText = `Error code ${error}`;
				const loading = getElementById("loading");
				emptyElement(loading);
				loading.append(small, h2, p);
			}
		},
	);
});
