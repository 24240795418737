export class SingleEventTarget<T> {
	private readonly listeners: ((e: T) => void)[];

	constructor() {
		this.listeners = [];
	}

	addEventListener(callback: (e: T) => void): void {
		this.listeners.push(callback);
	}

	removeEventListener(callback: (e: T) => void): void {
		const stack = this.listeners;
		for (let i = 0, l = stack.length; i < l; i++) {
			if (stack[i] === callback) {
				stack.splice(i, 1);
				return;
			}
		}
	}

	dispatchEvent(event: T): void {
		// Create a copy of the listeners array.
		const stack = this.listeners.slice();

		// Send event to all listeners.
		for (let i = 0, l = stack.length; i < l; i++) {
			stack[i](event);
		}
	}
}
